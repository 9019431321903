<template>
  <div class="withdraw">
    <!-- 导航栏 -->
    <Navbar :title="$t('newAdd.Withdraw')">
      <template #right>
        <button @click="handleLinkPage('withdraw-list')">
          {{ $t("newAdd.Record") }}
        </button>
      </template>
    </Navbar>
    <!-- <van-tabs v-model="withdrawType" type="card" class="tabs_wrap">
      <van-tab :title="$t('newAdd.cardTabBank')" name="bank"></van-tab>
      <van-tab :title="$t('newAdd.cardTabUSDT')" name="coin"></van-tab>
    </van-tabs> -->

    <!-- <van-cell @click="handleLinkPage(`add-bank-card?id=${bank_id}`)"> -->
    <van-cell @click="handleLinkPage(`walletList?id=${bank_id}`)">
      <div slot="title" class="title">
        <span class="item-title" v-if="withdrawType == 'bank'">
          {{ $t("newAdd.BankCard") }}
        </span>
        <span class="item-title" v-else>{{ $t("newAdd.WalletInfo") }}</span>
        <span class="item-right" v-if="bankInfo">
          <!-- <span class="item-value">{{ bankInfo.bank }}</span> -->
          <!-- language-ch 尾号 -->
          <span class="item-value-bank">
            <!-- tail number -->
            <div v-if="withdrawType == 'bank'">
              <span style="color: var(--gray3)">{{ $t("newAdd.Name") }}:</span
              >{{ "\n" + bankInfo.name ? bankInfo.name : "" }}
            </div>
            <div v-else>
              <span style="color: var(--gray3)">
                {{ $t("withdraw.currency.label") }}:
              </span>
              &nbsp;USDT
              <!-- {{ "\n" + bankInfo.bank ? bankInfo.bank : "USDT" }} -->
            </div>
            <div v-if="withdrawType == 'bank'">
              <span style="color: var(--gray3)"
                >{{ $t("newAdd.BankName") }}:</span
              >{{ "\n" + bankInfo.bank ? bankInfo.bank : "" }}
            </div>
            <div v-else>
              <span style="color: var(--gray3)"
                >{{ $t("newAdd.ChainType") }}:</span
              >{{ "\n" + bankInfo.open ? bankInfo.open : "" }}
            </div>
            <div v-if="withdrawType == 'bank'">
              <span style="color: var(--gray3)">{{ $t("newAdd.CardNum") }}:</span
              >{{ "\n" + bankInfo.card ? bankInfo.card : "" }}
            </div>
            <div v-else>
              <span style="color: var(--gray3)"
                >{{ $t("newAdd.WalletAddress") }}:</span
              >{{ "\n" + bankInfo.card ? bankInfo.card : "" }}
            </div>
          </span>
        </span>
        <span v-else>{{ $t("newAdd.Reading") }}</span>
      </div>
      <template #right-icon v-if="bankInfo.id">
        <van-icon name="arrow" slot="icon" />
      </template>
    </van-cell>

    <!-- language-ch 可提现余额 -->
    <van-cell>
      <div slot="title" class="balance">
        <span class="balance-title"
          >{{ $t("newAdd.AllBalance") }}
          <!-- Withdrawable balance -->
        </span>
        <span class="balance-right red"
          >{{ userInfo.money }}
          <!-- {{ money_can }} -->
        </span>
      </div>
      <!-- language-ch 总余额 -->
      <!-- <div slot="default" class="default">
        <span class="item-title">overall balance </span>
        <span class="item-value">{{
          userInfo.money ? userInfo.money : '  0.00'
        }}</span>
      </div> -->
    </van-cell>
    <!-- <van-cell v-if="withdrawType === 'coin'">
      <div slot="title" class="balance">
        <span class="balance-title">
          {{ `${bankInfo.bank} ${$t("newAdd.Amount")}` }}
        </span>
        <span class="balance-right red">
          {{ actualAmount }}
        </span>
      </div>
    </van-cell> -->

    <!-- language-ch 提现仍需消费：  -->
    <!-- <div class="item-need" v-if="userInfo.money_not > 0">
      Withdrawal will still be consumption:ZAR {{ userInfo.money_not }}
    </div> -->
    <!-- language-ch 是否可以提现 -->
    <!-- <van-cell>
      <div slot="title" class="balance">
        <span class="balance-title">Can I withdraw money</span>
        <span class="balance-right red"
          >{{ userInfo.money_not > 0 ? 'No' : 'Yes' }}
        </span>
      </div> -->
    <!-- 手续费 -->
    <!-- <div slot="default" class="default">
        <span class="item-title">procedure fee </span>
        <span class="item-value red">  0.00</span>
      </div>
    </van-cell> -->
    <!-- language-ch 请输入提现金额 -->
    <div class="withdraw_amount">
      <div class="title">{{ $t("withdraw.label.enterAmount") }}:</div>
      <van-field
        v-model="money"
        center
        v-if="country != 'tr'"
        clearable
        label-width="200"
        label=""
        type="number"
        :placeholder="$t('newAdd.EnterWithdrawalAmount')"
        :v-else="$t('newAdd.EnterWithdrawalAmount')"
      >
        <!-- <template #button>
        <button @click="allHandler">{{$t('newAdd.AllOut')}}</button>
      </template> -->
      </van-field>
    </div>

    <!-- language-ch 提 现 -->
    <button class="button" @click="Withdrawal">
      {{ $t("newAdd.AskNow") }}
    </button>

    <!-- 密码弹出层 -->
    <van-dialog
      v-model="isshowDialog"
      title="Password"
      show-cancel-button
      cancel-button-text="CANCEL"
      confirm-button-text="CONFIRM"
      confirmButtonColor="var(--theme)"
      @confirm="onSubmit"
      @cancel="editValue = ''"
    >
      <van-field
        v-model="editValue"
        autofocus="true"
        type="password"
        :placeholder="$t('newAdd.EnterPwdPlaceholder')"
      />
    </van-dialog>
    <van-dialog
      v-model="showAddBankWarn"
      :title="$t('newAdd.warmTitle')"
      show-cancel-button
      :cancel-button-text="$t('btn.cancel')"
      :confirm-button-text="$t('btn.confirm')"
      confirmButtonColor="var(--theme)"
      @confirm="toAddBank"
      @cancel="$router.back()"
      class="warmDialog"
    >
      <div class="dialog_content">
        {{
          verConfig.needBank
            ? this.$t("newAdd.NotHaveCard")
            : this.$t("newAdd.NotHaveWallet")
        }}
      </div>
    </van-dialog>
    <!-- <Tabbar active="new-withdraw" /> -->
  </div>
</template>

<script>
import { GET_USER_BANK_INFO, WITHDRAW, getUserInfoNew } from "@/api";
import { isObject, count, getUserInfo, getToken } from "@/utils";
// import { userBank, extract } from '@/api/use.js'
import { NavBar, cell, icon, field, Dialog, Toast, Tab, Tabs } from "vant";
import Cookies from "js-cookie";
import Tabbar from "@/components/Tabbar";
import verConfig from "../../utils/verConfig";
import Navbar from "@/components/JNav";
import { maskMiddle } from "@/utils/common"
export default {
  name: "",
  components: {
    [NavBar.name]: NavBar,
    [cell.name]: cell,
    [icon.name]: icon,
    [field.name]: field,
    [Toast.name]: Toast,
    [Dialog.Component.name]: Dialog.Component,
    [Tab.name]: Tab,
    [Tabs.name]: Tabs,
    Tabbar,
    Navbar,
  },
  data() {
    return {
      sms: "",
      country: Cookies.get("language"),
      money: "",
      bankInfo: {},
      userInfo: {},
      isshowDialog: false,
      editValue: "",
      money_not: 0,
      // isshowpassword: this.$route.query.isshowpassword
      //   ? this.$route.query.isshowpassword
      //   : false,
      paypassword: this.$route.query.paypassword
        ? this.$route.query.paypassword
        : true,
      withdrawType: verConfig.needBank ? "bank" : "coin",
      // actualAmount: 0,
      rateData: {},
      showAddBankWarn: false,
    };
  },
  // watch: {
  //   money: (val)=> {
  //     if()
  //   }
  // },
  created() {
    getUserInfoNew();
  },
  mounted() {
    this.getUserBankInfo();
    this.userInfo = getUserInfo();
  },
  methods: {
    handleLinkPage(path) {
      if (getToken()) {
        this.$router.push(`/${path}`);
      } else {
        this.$toast($t("newAdd.NotLogin"));
        setTimeout(() => {
          this.$router.replace({ name: "login" });
        }, 1000);
      }
    },
    bankInfoInit(data) {
      this.bank = data.bank;
      this.bank = data.bank;
      this.bank_id = data.id;
      this.bank_name = data.name;
      this.bank_cardl4 = data.card_l4;
      this.bankInfo = {...data,card: maskMiddle(data.card)};
      if (!!data.open && data.open !== "bank") {
        this.withdrawType = "coin";
        // this.$api.getWithdrawRate((res) => {
        //   const openRateData = res.find(
        //     (item) => item.name.toUpperCase() == data.open
        //   );
        //   if (!!openRateData) {
        //     this.rateData = openRateData;
        //     return;
        //   }
        //   const bankRateData = res.find(
        //     (item) => item.name.toUpperCase() == data.bank
        //   );
        //   if (!!bankRateData) {
        //     this.rateData = bankRateData;
        //   }
        // });
      } else {
        this.withdrawType = "bank";
      }
    },
    toAddBank() {
      this.itemClick("BankCardAdd");
    },
    getUserBankInfo() {
      const bankInfo = JSON.parse(this.$cookie.get("selectBankInfo"));
      // if (bankInfo) {
      //   this.bankInfoInit(bankInfo);
      //   return;
      // }
      Toast.loading({
        forbidClick: true,
        duration: 0,
      });
      GET_USER_BANK_INFO()
        .then((r) => {
          Toast.clear();
          if (r.data.ret === 1) {
            Toast.clear();
            if (isObject(r.data.data) && count(r.data.data) > 0) {
              const banklist = r.data.data
              if(bankInfo){
                if (banklist.some(item => item.id === bankInfo.id)) {
                  this.bankInfoInit(bankInfo)
                  return
                }
                this.bankInfoInit(banklist[0]);
                this.$cookie.set("selectBankInfo", JSON.stringify(banklist[0]))
              }else{
                this.bankInfoInit(banklist[0]);
                this.$cookie.set("selectBankInfo", JSON.stringify(banklist[0]))
              }
            } else {
              Toast.clear();
              // language-ch 您尚未设置银行卡，是否前往设置？
              this.showAddBankWarn = true;
              // Dialog.confirm({
              //   title: "warm prompt",
              //   confirmButtonText: "Confirm",
              //   cancelButtonText: "Cancel",
              //   message: verConfig.needBank
              //     ? this.$t("newAdd.NotHaveCard")
              //     : this.$t("newAdd.NotHaveWallet"),
              //   // 'You have not set up your bank card, do you want to go to the setting?',
              // })
              //   .then((res) => {
              //     this.itemClick("BankCardAdd");
              //   })
              //   .catch((err) => {
              //     this.$router.back();
              //   });
            }
          } else {
            Toast.clear();
            // Toast("拉取银行信息失败");
            Toast(this.$t("newAdd.GetBankInfoFail"));
            // this.$router.back()
          }
        })
        .catch((e) => {
          Toast.clear();
          console.error(e);
        });
    },
    itemClick(page) {
      this.$router.push({ name: page });
    },
    allHandler() {
      // this.money = this.money_can
      this.money = this.userInfo.money;
      // if (this.money_can !== 0) {
      //   this.money = this.money_can
      // }
    },
    Withdrawal() {
      // if (this.money_can === 0) {
      //   Toast('The withdrawal balance is zero')
      //   return
      // }
      // if (this.money === 0) {
      //   Toast('Please fill in the amount')
      //   return
      // }
      // this.isshowDialog = true
      this.confirm();
    },
    confirm() {
      Toast.loading({
        forbidClick: true,
        duration: 0,
      });
      WITHDRAW({
        money: this.money,
        // paypass: this.editValue,
        bankid: this.bank_id,
      })
        .then((r) => {
          Toast.clear();
          if (r.data.ret === 1) {
            Toast.clear();
            Toast(r.data.msg);
            getUserInfoNew();
            this.$router.back();
          } else {
            Toast.clear();
            Toast(r.data.msg);
            // setTimeout(() => {
            //   this.$router.replace({ name: 'login' })
            // }, 1000)
          }
        })
        .catch((e) => {
          Toast.clear();
          console.error(e);
        });
    },
    // 提交提现
    async onSubmit() {
      this.isshowDialog = false;
      if (this.editValue.trim().length !== 4) {
        Toast(this.$t("newAdd.EnterValidPayPwd"));
      } else {
        const { data } = await extract({
          money: this.money,
          paypass: this.editValue,
          bankid: this.bankInfo.id,
        });
        Toast(data.msg);
        this.editValue = "";
        this.$router.push("/login");
      }
    },
  },
  computed: {
    money_can() {
      return this.userInfo.money_not > 0 ? 0 : this.userInfo.money;
    },
    actualAmount() {
      let amount = 0;
      if (Number(this.money)) {
        if (String(this.rateData.value).indexOf("%") === -1) {
          amount = (this.money - this.rateData.value) / this.rateData.rate;
        } else {
          const fixedNum = 100000000;
          amount =
            Math.round(
              ((this.money *
                (1 -
                  String(this.rateData.value).substring(
                    0,
                    String(this.rateData.value).indexOf("%")
                  ) /
                    100)) /
                this.rateData.rate) *
                fixedNum
            ) / fixedNum;
        }
      }
      return amount > 0 ? amount : 0;
    },
  },
};
</script>

<style lang="less" scoped>
// @nav-bar-background-color: #ff0000;
// @tabs-default-color: #ff0000;
@wrapPadding: calc(12rem / 16);
@comRadius: 10px;
// @comBG: linear-gradient(#093958, #022c47);
@comBG: var(--bg1);
.withdraw {
  width: 100%;
  height: 100vh;
  background: var(--hallBg);
  .warmDialog {
    background: var(--bg);
    .dialog_content {
      font-size: calc(15rem / 16);
      padding: calc(18rem / 16);
      color: var(--gold);
    }
    /deep/ .van-dialog__cancel,
    /deep/ .van-dialog__confirm {
      background: var(--bg) !important;
    }
    /deep/ .van-dialog__cancel {
      color: #4acfff;
    }
    /deep/ .van-dialog__header {
      color: var(--gold);
    }
  }
  .item-title {
    color: var(--btnsizeColor);
  }
  .item-value-bank {
    color: var(--gold);
  }
  .balance-title {
    color: var(--btnsizeColor);
  }
  .van-icon {
    color: var(--gold);
  }
  button {
    // background-color: #fff;
    background-color: var(--bg);
    border: none;
    color: var(--textColor);
    font-size: calc(14rem / 16);
    padding: 6px 12px;
    border-radius: 4px;
  }
  //   .iconfont {
  //     font-size: calc(22rem / 16);
  //     color: var(--textColor);
  //   }
  // }
  .van-cell {
    // margin: calc(7.5rem / 16) 0;
    margin: @wrapPadding;
    width: calc(100% - @wrapPadding * 2);
    border-radius: @comRadius;
    font-size: calc(12rem / 16);
    color: #000;
    display: flex;
    align-items: center;
    background: var(--moduleColor);
    &::after {
      border: none;
    }
    // i {
    //   // margin-top: calc(18rem / 16);
    // }
    .title {
      display: flex;
      align-items: center;

      .item-right {
        width: calc(250rem / 16);
        display: flex;
        flex-direction: row;
        margin-left: calc(15rem / 16);
      }
    }

    .balance {
      width: calc(185rem / 16);

      .balance-title {
        width: calc(200rem / 16);
      }

      .balance-right {
        margin-left: calc(10rem / 16);
      }
    }

    .default {
      color: #000;

      .item-value {
        margin-left: calc(10rem / 16);
      }
    }

    .red {
      color: var(--gold);
      // color: #ff0000;
    }
  }

  .item-need {
    color: #dc1323;
    text-align: right;
    font-size: calc(12rem / 16);
    margin-top: calc(12rem / 16);
    margin-right: calc(12rem / 16);
    margin-bottom: calc(12rem / 16);
  }
  .withdraw_amount {
    margin: @wrapPadding;
    .title {
      color: var(--btnsizeColor);
      font-size: calc(14rem / 16);
      text-indent: 0.6em;
    }
    .van-cell {
      margin: calc(6rem / 16) 0 0;
      width: 100%;
    }
  }
  .van-field {
    font-size: calc(15rem / 16);

    /deep/.van-field__label {
      width: calc(15rem / 16);
    }

    button {
      border: none;
      // color: #ff0000;
      color: var(--theme);
      background-color: #fff;
    }
  }

  .button {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    width: calc(340rem / 16);
    height: calc(43rem / 16);
    font-size: calc(15rem / 16);
    margin-top: calc(31rem / 16);
    // background-color: #ff0000;
    background: var(--theme);
    border-radius: calc(5rem / 16);
    border: none;
    // color: var(--textColor);
    color: var(--light);
  }

  .van-field {
    /deep/.van-field__body {
      input {
        text-align: center;
        color: var(--gold);
      }
    }
  }
}
</style>